import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Adsense from '../components/Adsense'
import Disqus from '../components/Disqus'
import Share from '../components/Share'
import { ProductExtended } from '../components/Product'
import './index.scss'

class Extended extends React.Component {
  render() {
    const partial = this.props.data.markdownRemark
    const title = 'Extended Ascii Table Online'
    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={title}
          description="Complete Extended Ascii Table Reference."
          article={false}
          pathname={this.props.location.pathname}
        />
        <ProductExtended siteMeta={this.props.data.site.siteMetadata} />
        <div className="home-ad">
          <Adsense type="article-top-responsive" />
        </div>
        <div className="home-blog-section">
          <Container>
            <div className="blog-about">
              <div
                className="info-group"
                dangerouslySetInnerHTML={{ __html: partial.html }}
              ></div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="home-ad">
            <Adsense type="article-top-responsive" />
          </div>
        </Container>
        <Container>
          <div className="page-share">
            <Share title={title} pathname={this.props.location.pathname} />
          </div>
        </Container>
        <Container>
          <div className="page-comments">
            <Disqus
              location={this.props.location}
              disqusShortname={
                this.props.data.site.siteMetadata.disqusShortname
              }
            />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Extended

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    markdownRemark(
      frontmatter: {
        type: { eq: "partial" }
        name: { eq: "extended-ascii-table" }
      }
    ) {
      html
    }
  }
`
